<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="12 9@md">
					<Stack direction="column" align="left" space="3">
						<Stack direction="column" width="100%" align="left" space="2">
							<Images width="100%" image="theme-wm/blog-large.jpg" />
							<Heading size="6" uppercase>JULY 1, 2021 / CONSULTING EVENTS</Heading>
							<Heading size="2">Finding cleaner ways to power the world today</Heading>
							<Paragraph>Alia mucius ex nam, ea facer liber usu, est aliquam eu platonem. Ut commune similique tation magna theophrastus mel, in cum. Expetendis sed, vel et possit oportere. Cum at tantas percipit facilisi. Graeci semper blandit et per, ea harum exerci definitionem mei. Cu eam soleat nostro convenire, vis laudem. Alterum utroque cu, cum ponderum. Accommodare delicatissimi ut per ornatus omittantur an, eros magna eripuit te vis. Semper reprehendunt cum te. Quo laudem adipisci ea, ius choro phaedrum cu. Ludus tibique ocurreret ne pri, at noster iudicabit evertitur cotidieque per. Cum at tantas percipit facilisi. Graeci semper blandit et pe</Paragraph>
							<Paragraph>Solet conceptam an quo. Ut tamquam elaboraret quo. Pri ut detraxit iudicabit efficiantur. Aeque vitae appetere eu est, duo at aeque explicari, laboramus maiestatis quo ex. Ad eam amet simul exerci, cum laudem tibique aliquando ei. Mel porro mucius persius at, illum officiis no ius. Sea et integre copiosae euripidis, et elit ancillae eam, eos ex tation postulant liberavisse. Soleat audire cotidieque eum eu, dicat efficiendi duo in. Quo eu legere eligendi, sed ea atomorum efficiantur.</Paragraph>
							<Quote>Duis sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accumsan ipsum velit. Nam nec tellus a tincid</Quote>
							<Paragraph>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat. Duis aute irure dolor in reprehenderit in voluptate velit.</Paragraph>
						</Stack>
						<Stack direction="row" justify="spaceBetween">
							<Paragraph>
								<Stack width="100%" space="1">
									<Link text="Banking" display="inline" url="/windermere/post" />
									<Link text="Innovation" display="inline" url="/windermere/post" />
									<Link text="Marketing" display="inline" url="/windermere/post" />
									<Link text="News" display="inline" url="/windermere/post" />
									<Link text="Projects" display="inline" url="/windermere/post" />
								</Stack>
							</Paragraph>
							<Stack space="1" justify="right" align="center" width="auto">
								<Heading size="5" uppercase>Share: </Heading>
								<Icon icon="email" border size="small" />
								<Icon icon="thumb_up" border size="small" />
								<Icon icon="favorite_outline" border size="small" />
							</Stack>
						</Stack>
						<Card color="light">
							<CardSection>
								<Stack direction="column row@sm" space="2">
									<Stack width="auto">
										<Images height="140px" width="140px" circle image="theme-wm/staff3.jpg" />
									</Stack>
									<Stack direction="column" align="left" space="2">
										<Heading size="5" uppercase>Alex Bryan</Heading>
										<Paragraph>Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.</Paragraph>
										<Stack space="1" justify="left" align="center">
											<Icon icon="email" border size="small" />
											<Icon icon="thumb_up" border size="small" />
											<Icon icon="favorite_outline" border size="small" />
										</Stack>
									</Stack>
								</Stack>
							</CardSection>
						</Card>
					</Stack>
				</GridContainer>
				<GridContainer size="12 3@md">
					<Stack direction="column" align="left" space="3">
						<Form>
							<FormContainer size="12">
								<Input placeholder="Search..." />
							</FormContainer>
						</Form>
						<Stack direction="column" align="left" space="2">
							<Heading size="4">Category</Heading>
							<Stack direction="column" align="left" space="1">
								<Heading size="6"><Link text="BUSINESS (10)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="CAMPAIGN (9)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="CONSULTING (8)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="DESIGN (9)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="EVENTS (3)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="MARKETING (1)" url="/windermere/post" /></Heading>
								<Heading size="6"><Link text="OFFICE (1)" url="/windermere/post" /></Heading>
							</Stack>
						</Stack>
						<Stack direction="column" align="left" space="2">
							<Heading size="4">Recent Posts</Heading>
							<Stack direction="column" align="left">
								<Images height="200px" width="100%" image="theme-wm/blog4.jpg" />
								<Heading size="6" margin="none" uppercase>JUNE 30, 2021</Heading>
								<Heading size="5">Solving problems together</Heading>
							</Stack>
							<Stack direction="column" align="left">
								<Images height="200px" width="100%" image="theme-wm/blog5.jpg" />
								<Heading size="6" margin="none" uppercase>JUNE 30, 2021</Heading>
								<Heading size="5">Sustainable use of capital</Heading>
							</Stack>
						</Stack>
						<Stack direction="column" align="left" space="2">
							<Heading size="4">Follow Us</Heading>
							<Stack space="1" justify="left" align="center">
								<Icon icon="email" border size="small" />
								<Icon icon="thumb_up" border size="small" />
								<Icon icon="favorite_outline" border size="small" />
							</Stack>
						</Stack>
					</Stack>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/windermere/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
